import React, { useState, useEffect, useRef } from 'react'
import { getAgenda } from '../../../../crud/dedicatedEvent-crud';
import moment from 'moment';
import Agenda from '../../GeneralComponents/Agenda/Agenda';
import DateDropdown from '../../../../components/CustomDropdown/DateDropdown';
import Loading from '../../../../components/Loading';
import useOnScreen from '../../../../components/useOnScreen';
import AgendaDatesTab from '../../GeneralComponents/Agenda/AgendaDatesTab';
import AgendaTracksTab from './AgendaTracksTab';
import AgendaSessions from './AgendaSessions';
import { errorToaster } from '../../../../helpers/utils';
import _ from "lodash"

const customStyles = {
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused ? "#FFFFFF" : isSelected ? "#FFFFFF" : undefined,
        color: isFocused ? "#FDB14B" : isSelected ? "#FDB14B" : undefined,
        zIndex: 1,
        cursor: 'pointer'
    }),

    dropdownIndicator: base => ({
        ...base,
        color: "#FFFFFF",
        "&:hover": {
            color: "#572148"
        }
    }),
    control: (base, state) => ({
        ...base,
        background: "#FDB14B",
        color: '#FFFFFF',
        border: state.isFocused ? "1px solid #572148" : "1px solid #cdd4db",
        boxShadow: "none",
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '120%'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, left: '20%', color: '#FFFFFF' }),
};

const AgendaMinimalistic = ({ eventData, setCurrSection }) => {
    const [loading, setLoading] = useState(false)
    const [agenda, setAgenda] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [agendaDates, setAgendaDates] = useState([])

    const [selectedTrackId, setSelectedTrackId] = useState('')
    const ref = useRef(null)

    // const isVisible = useOnScreen(ref)
    // useEffect(() => {
    //     if (isVisible) {
    //         setCurrSection("agenda")
    //     }
    // }, [isVisible])

    const today = moment(eventData.start_date).format("YYYY-MM-DD")
    const [selectedTrackDate, setSelectedTrackDate] = useState(today)
    // console.log('dateValue', dateValue)

    const getAgendaFunction = async (date) => {
        setLoading(true)
        try {
            const res = await getAgenda(eventData.event_id, date);
            setAgenda(res.data);
            setSelectedTrackId(res.data[0].track_id)
            setLoading(false)
        } catch (error) {
            setAgenda([])
            if (error.response.data.error == "No tracks found for the date") {
                setErrorMessage("Sessions for this date are yet to be updated")
            } else errorToaster(error.response.data.error)
            setLoading(false)
            // errorToaster(error.response.data.error);
        }
    }

    useEffect(() => {
        getAgendaFunction(selectedTrackDate)
        getAllAgendaDates();
    }, [selectedTrackDate])

    const getAllAgendaDates = () => {
        const dates = [];
        const currentDate = moment(eventData.start_date);
        const finalDate = moment(eventData.end_date);

        while (currentDate.isSameOrBefore(finalDate, 'day')) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        }

        setAgendaDates(dates)
    }

    return (
        <section id="agenda" ref={ref}>
            <div className='head '>
                <h2 className=''>SCHEDULE</h2>
            </div>
            {/* <div className='d-flex justify-content-end align-items-end date-change-div'>
                <DateDropdown
                    isSearchable={false}
                    className="select"
                    icon={'fa fa-calendar'}
                    options={
                        arrayOfObjectsDates.map((e, key) => ({
                            label: moment(e.date).format('ddd, D MMM'),
                            value: moment(e.date).format("YYYY-MM-DD"),
                        }))
                    }
                    defaultValue={{ label: moment(`${dateValue}`).format('ddd, D MMM'), value: dateValue.toString() }}
                    // components={{ ValueContainer }}
                    onChange={(e) => {
                        // console.log('e.value', e.value)
                        setDateValue(e.value);
                        getAgendaFunction(e.value)
                    }}
                    styles={customStyles}
                />
            </div> */}
            {agendaDates.length > 1 && selectedTrackDate && <AgendaDatesTab
                agendaDates={agendaDates}
                selectedTrackDate={selectedTrackDate}
                setSelectedTrackDate={setSelectedTrackDate}
                selectedDateBackground={"black"}
                otherTextColor={"black"}
                background={"white"}
            />}
            {loading ? <div className='loading d-flex justify-content-center align-items-center'>
                <Loading color={`#000`}/>
            </div>
                : (agenda && selectedTrackDate) && (
                    <>
                        <div>
                            {(!_.isEmpty(agenda)) && <AgendaTracksTab
                                agendaTracks={agenda}
                                selectedTrackId={selectedTrackId}
                                setSelectedTrackId={setSelectedTrackId}
                            />}
                            {(!_.isEmpty(agenda)) && <AgendaSessions
                                selectedTrackData={agenda.filter((trackData) => trackData.track_id === selectedTrackId)}
                                eventData={eventData}
                            />}
                        </div>
                        {(_.isEmpty(agenda)) && <div className="no-agenda-text mt-2">{errorMessage}</div>}
                    </>
                )
            }
            <style jsx global>{`
            .date-change-div{
                margin-right: 20px
            }
            .select{
                width: 13%;
                margin-right: 20px;
            }
            .loading{
                margin-top: 60px;
                padding-bottom: 50px;
            }
            .no-agenda-text{
                font-weight: 400;
            }
        `}</style>
        </section>
    )
}

export default AgendaMinimalistic

