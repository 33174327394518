import { Children, Component, useEffect, useRef, useState } from "react";

/**
 *  Tab component based on Material UI Tabs
 *
 * @param {React.FunctionComponent} props
 * @returns {JSX.Element}
 */
export function Tabs({ children, onTabChange }) {
	const [currendIndex, setCurrentIndex] = useState(0);
	const [buttonsWidth, setButtonsWidth] = useState(0);
	const overflowRef = useRef();
	const buttonsRef = useRef([]);
	buttonsRef.current = [...Array(Children.length).keys()];
	// if (children && children.length > 1) {
	// 	Children.forEach(children, child => {
	// 		if (child.type.name !== Tab.name) {
	// 			throw Error("Tabs must only have `Tab` children");
	// 		}
	// 	});
	// } else {
	// 	throw Error("Tab must have atleast 2 Tab");
	// }

	const setActiveTab = index => {
		setCurrentIndex(index);
		if (onTabChange) {
			onTabChange(index);
		}
		if (children[index].props.onClick) {
			children[index].props.onClick(index);
			setTimeout(() => setCurrentIndex(0), 1000);
		}
	};

	const tabTitles = Children.map(children, (child, index) => {
		if (children[index] && child) {
			return (
				<button
					ref={el => {
						buttonsRef.current[index] = el;
					}}
					className={`konf-tab-title mx-0 ${currendIndex == index ? "active" : ""}`}
					key={index}
					disabled={child.disabled}
					onClick={() => setActiveTab(index)}
				>
					{child.props.title}
				</button>
			);
		}
	});

	useEffect(() => {
		console.log(buttonsRef);
		if (buttonsRef.current && buttonsRef.current.length > 0) {
			let btnWidth = 0;
			buttonsRef.current.forEach(btn => {
				btnWidth += btn.offsetWidth;
			});
			setButtonsWidth(btnWidth);
		}
	}, []);

	return (
		<>
			<div className="tabs-container d-flex">
				{overflowRef?.current?.offsetWidth < buttonsWidth && (
					<button
						onClick={() => (overflowRef.current.scrollLeft -= 150)}
						className="btn-arrow-tab"
					>
						<i className="fa-solid fa-chevron-left"></i>
					</button>
				)}
				<div
					className="d-flex justify-content-between w-100 tab-overflow-scroll"
					ref={overflowRef}
				>
					{tabTitles}
				</div>
				{overflowRef?.current?.offsetWidth < buttonsWidth && (
					<button
						onClick={() => (overflowRef.current.scrollLeft += 150)}
						className="btn-arrow-tab"
					>
						<i className="fa-solid fa-chevron-right"></i>
					</button>
				)}
			</div>
			<style jsx global>
				{`
					button:focus {
						box-shadow: none;
					}

					.tab-overflow-scroll::-webkit-scrollbar {
						width: 1px;
					}

					.tab-overflow-scroll::-webkit-scrollbar-track {
						background: transparent;
					}

					.tab-overflow-scroll::-webkit-scrollbar-thumb {
						background-color: transparent;
					}

					.btn-arrow-tab {
						background: transparent;
						border: none;
					}
					.konf-tab-title {
						position: relative;
						display: inline-block;
						text-decoration: none;
						padding: 15px;
						font-weight: 600;
						font-size: 1rem;
						color: var(--fourthColor);
						background-color: transparent;
						text-align: center;
						vertical-align: middle;
						outline: none;
						border: none;
						white-space: nowrap;
						transition: left 0.2s ease;
					}
					.konf-tab-title:not(.active):hover {
						color: var(--primaryColor);
						background-color: #c3c3c375 !important;
					}
					.konf-tab-title:not(.active):disabled {
						color: #d1d1d1;
					}
					.konf-tab-title:not(.active):active {
						background-color: #c3c3c375;
					}
					.konf-tab-title.active {
						color: #000;
						border-bottom: 2px solid #000;
					}
					.tabs-container > div > div > div {
						border-bottom: 1px solid #cccbcb;
					}
					.tabs-container {
						border-bottom: 1px solid #d6d6d6;
					}
					.tabs-container > div {
						scroll-behavior: smooth;
						overflow-x: auto;
					}
					@media (min-width: 992px) and (max-width: 1368px) {
						.konf-tab-title {
							font-size: 0.875rem;
						}
					}
					@media (min-width: 1130px) {
						.tabs-container > div > div {
							min-width: fit-content !important;
						}
					}
					@media (min-width: 768px) and (max-width: 991px) {
						.tabs-container > div > div {
							min-width: fit-content !important;
						}
					}
				`}
			</style>
		</>
	);
}

export function Tab(props) {
	if (!props.title) {
		throw Error("Tab must have title");
	}
	return props.children;
}

Tab.defaultProps = {
	disabled: false
};
